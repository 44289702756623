import React, { useEffect } from "react"
import { Route, Routes, useLocation } from 'react-router-dom'
import HomePage from "./Views/home"
import ProductsList from "./Views/produtos"

const ScrollToTop = () => {
    const { pathname } = useLocation()
  
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])
  
    return null
  }

const MainRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/produtos" element={<ProductsList />} />
      </Routes>
    </>
  )
}

export default MainRoutes
