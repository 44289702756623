import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Cabecalho from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';
import './estilos/estilos.css'; // Certifique-se de importar seu arquivo CSS

function App() {
  return (
    <div className="app-container">
      <Router>
        <Cabecalho />
        <div className="content">
          <Routes />
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
