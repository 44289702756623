import React from 'react';
import { Link } from 'react-router-dom';
import "../estilos/estilos.css";

const Cabecalho = () => {
  return (
    <nav className="menu navbar navbar-light fixed-top">
      <ul className="navbar-nav ml-auto flex-row align-items-center">
        <li className="nav-item">
          <Link className="nav-nome nav-link" to="/">
            Nosso Casamento
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-nome nav-link" to="/produtos">
            Presentes
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Cabecalho;
