import React from "react";
import AreaCategoria from "../components/Home/areaCategoria";
import "../estilos/estilos.css";

const HomePage = () => {
  return (
    <>
      <AreaCategoria />
    </>
  );
};

export default HomePage;
