import React from "react";
import ProductsPage from "../components/Products/index";

const ProductsList = () => {
  return (
    <>
      <ProductsPage />
    </>
  );
};
export default ProductsList;
