import React, { useState, useEffect } from "react";
import "../../estilos/estilos.css";
import "../../estilos/home.css";
import namePage from "../../img/name-page.png";
import { FaCircle } from "react-icons/fa";
import layer from "../../img/Layer.png";

const AreaCategoria = () => {
  const CountdownSquare = ({ value, title }) => (
    <div className="countdown-square">
      <h2>{value}</h2>
      <span>{title}</span>
    </div>
  );

  const targetDate = new Date("2024-09-14T00:00:00");

  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const addLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <>
      <div className="container">
        <div className="area-container">
          <div
            className="area-img"
            style={{
              pointerEvents: "none",
              userSelect: "none",
            }}
          >
            <img src={namePage} alt="Nome da Página" width={"100%"} />
          </div>
          <div className="quote">
            <p>
              "Foi o Senhor que fez isto, e é maravilhoso aos nossos olhos"
              Salmos 118:23
            </p>
          </div>
          <div className="separator">
            <p>______________________________</p>
          </div>
          <div className="date-container">
            <span>14</span>
            <FaCircle style={{ margin: "0 14px", fontSize: "8px" }} />
            <span>SETEMBRO</span>
            <FaCircle style={{ margin: "0 14px", fontSize: "8px" }} />
            <span>2024</span>
          </div>
          <div
            style={{
              fontFamily: "EB Garamond",
              textAlign: "center",
              marginTop: "20px",
              color: "#525F65",
            }}
            className={"title-count"}
          >
            <p>CONTAGEM REGRESSIVA PARA O GRANDE DIA</p>
          </div>

          <div className="countdown">
            <CountdownSquare
              title={"Dias"}
              value={addLeadingZeros(timeLeft.days)}
            />
            <CountdownSquare
              title={"Horas"}
              value={addLeadingZeros(timeLeft.hours)}
            />
            <CountdownSquare
              title={"Minutos"}
              value={addLeadingZeros(timeLeft.minutes)}
            />
            <CountdownSquare
              title={"Segundos"}
              value={addLeadingZeros(timeLeft.seconds)}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="main-section">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            margin: "40px 0",
          }}
        >
          <h1 className="title-main-section" style={{ color: "#597455" }}>
            Sim, vamos casar!!!
          </h1>
          <img className="img-main-section" src={layer} />
        </div>
        <div>
          <p>
            É com imensa alegria que compartilhamos esta notícia especial e
            convidamos você para celebrar conosco neste grande dia.
          </p>
          <p>
            Com a certeza de que "Foi o Senhor que fez isto, e é maravilhoso aos
            nossos olhos" (Salmos 118:23), reconhecemos e somos gratos pela obra
            de Deus em nossas vidas. Sua presença é fundamental para nós neste
            momento significativo.
          </p>
          <p>
            Será um momento não apenas de união, mas também de testemunho do
            amor e da fidelidade de Deus. Estamos ansiosos para compartilhar
            esse momento sob a orientação e bênção divina
          </p>
          <p>
            Que este dia seja marcado pela alegria, pela comunhão e pelo
            reconhecimento da graça de Deus em nossa jornada juntos.
          </p>
          <p>Contamos com você para tornar este dia ainda mais especial.</p>
          <p>Esperamos ansiosamente por sua presença!</p>
        </div>
      </div>
    </>
  );
};

export default AreaCategoria;
