import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../../estilos/estilos.css";
import "../../estilos/cardItem.css";
import { ClipLoader } from "react-spinners";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";

const CardItem = () => {
  const [produtos, setProdutos] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [pixModalVisible, setPixModalVisible] = useState(false); // Novo estado para o modal do Pix
  const [selectedProduto, setSelectedProduto] = useState(null);
  const [selectedCategoria, setSelectedCategoria] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [nome, setNome] = useState("");
  const [nomeErro, setNomeErro] = useState("");
  const [enabledCount, setEnabledCount] = useState(0);
  const [produtosPorPagina, setProdutosPorPagina] = useState(12);

  const fetchData = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_API_URL_DEV;
      const res = await fetch(url);
      const data = await res.json();
      const produtosArray = data?.crud || [];

      // Ordenando os produtos para que "Utilidades Domésticas" apareçam por último
      const sortedProdutos = produtosArray.sort((a, b) => {
        if (
          a.category === "Utilidades Domésticas" &&
          b.category !== "Utilidades Domésticas"
        ) {
          return 1;
        } else if (
          a.category !== "Utilidades Domésticas" &&
          b.category === "Utilidades Domésticas"
        ) {
          return -1;
        }
        return 0;
      });

      setProdutos(sortedProdutos);
      setCurrentPage(1);
      setEnabledCount(
        sortedProdutos.filter((produto) => produto.enabled).length
      );
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      if (width <= 799) setProdutosPorPagina(12);
      else if (width <= 1099) setProdutosPorPagina(15);
      else if (width <= 1399) setProdutosPorPagina(16);
      else if (width <= 1599) setProdutosPorPagina(20);
      else setProdutosPorPagina(24);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log("Produtos carregados:", produtos);
    console.log("Categoria selecionada:", selectedCategoria);
  }, [produtos, selectedCategoria]);

  const openModal = (produto) => {
    setSelectedProduto(produto);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedProduto(null);
    setModalVisible(false);
    setNome("");
    setNomeErro("");
  };

  const validateNome = () => {
    if (!nome.trim()) {
      setNomeErro("O nome é obrigatório.");
      return false;
    }
    setNomeErro("");
    return true;
  };

  const confirmToggleEnabled = async () => {
    if (selectedProduto) {
      if (!validateNome()) return;

      try {
        setLoading(true);
        const id = selectedProduto._id;
        const url = `${process.env.REACT_APP_API_URL_EDIT}?_id=${id}`;
        const bodyData = {
          enabled: true,
          name: nome,
        };
        const res = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });
        if (!res.ok) throw new Error("Falha ao atualizar o estado do produto");

        const updatedProdutos = produtos.map((produto) =>
          produto._id === selectedProduto._id
            ? { ...produto, enabled: true }
            : produto
        );
        setProdutos(updatedProdutos);
        setEnabledCount(
          updatedProdutos.filter((produto) => produto.enabled).length
        );
        closeModal();
      } catch (error) {
        console.error("Erro ao confirmar ação:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCategoriaChange = (selectedOption) => {
    const categoria = selectedOption ? selectedOption.value : "";
    setSelectedCategoria(categoria);
    setCurrentPage(1);
  };

  const categorias = [...new Set(produtos.map((produto) => produto.category))];
  const categoriasOrdenadas = categorias.sort();

  const categoriaOptions = [
    { value: "", label: `Todos os presentes` },
    {
      value: "disponiveis",
      label: `Presentes disponíveis  (${produtos.length - enabledCount})`,
    },
    { value: "escolhidos", label: `Presentes escolhidos (${enabledCount})` },
    ...categoriasOrdenadas.map((categoria) => ({
      value: categoria,
      label: categoria,
    })),
  ];

  const produtosFiltrados = produtos
    .filter((produto) =>
      produto.title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((produto) => {
      switch (selectedCategoria) {
        case "disponiveis":
          return !produto.enabled;
        case "escolhidos":
          return produto.enabled;
        case "chá de cozinha":
          return false;
        default:
          return selectedCategoria
            ? produto.category === selectedCategoria
            : true;
      }
    });

  const produtosExibidos = produtosFiltrados.slice(
    0,
    currentPage * produtosPorPagina
  );

  const carregarMaisProdutos = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "200px",
      borderColor: state.isFocused ? "#597455" : "#cccccc",
      "&:hover": {
        borderColor: "#597455",
      },
      boxShadow: state.isFocused ? "0 0 0 1px #597455" : "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#597455"
        : state.isFocused
        ? "#e2e2e2"
        : null,
      color: state.isSelected ? "#ffffff" : "#000000",
      "&:hover": {
        backgroundColor: state.isSelected ? "#597455" : "#e2e2e2",
      },
    }),
  };

  const inputStyles = {
    outline: "none",
    width: "200px",
    height: "40px",
  };

  const [pixCopied, setPixCopied] = useState(false);

  const copyPixToClipboard = () => {
    const pixKey = "bassrafa75@gmail.com";
    navigator.clipboard.writeText(pixKey).then(() => {
      setPixCopied(true);
      setTimeout(() => {
        setPixCopied(false);
      }, 2000);
    });
  };

  return (
    <>
      {loading && (
        <div className="spinner-container">
          <ClipLoader size={50} color={"#597455"} loading={loading} />
        </div>
      )}
      {!loading && (
        <>
          <div>
            <div>
              {!isEmpty(produtosExibidos) && (
                <div className="select-produtos">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={categoriaOptions.find(
                      (option) => option.value === selectedCategoria
                    )}
                    onChange={handleCategoriaChange}
                    options={categoriaOptions}
                    placeholder="Todos os produtos"
                    styles={customStyles}
                    isSearchable={false}
                  />
                  <button
                    className="btn-pix"
                    onClick={copyPixToClipboard}
                    style={{ width: 200, justifyContent: "center" }}
                  >
                    {pixCopied ? (
                      <>
                        {" "}
                        <FontAwesomeIcon icon={faCheck} className="icon" /> Pix
                        copiado!{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <FontAwesomeIcon icon={faCopy} className="icon" />
                        Presentear com Pix
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
            <div className="grid-container">
              {produtosExibidos &&
                produtosExibidos.map((produto) => (
                  <div
                    className={`card ${
                      produto.enabled ? "card-desabilitado" : ""
                    }`}
                    key={produto._id}
                  >
                    <img
                      src={produto.image}
                      className="card-img-top"
                      alt={produto.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{produto.title}</h5>
                      <button
                        className="card-btn btn mt-auto"
                        onClick={() => openModal(produto)}
                      >
                        Selecionar presente
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {produtosFiltrados.length > produtosExibidos.length && !loading && (
            <div className="text-center my-3 mt-5">
              <button
                className="btn btn-confirm"
                onClick={carregarMaisProdutos}
              >
                Carregar mais produtos
              </button>
            </div>
          )}
          {modalVisible && selectedProduto && (
            <div className="modal-overlay">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      onClick={closeModal}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <h2 style={{ textAlign: "center" }}>
                      Agradecemos muito por ter escolhido este presente!
                    </h2>
                    <br />
                    <p>
                      Sinta-se à vontade para enviar para o nosso endereço:{" "}
                      <b>
                        Rua Pedro Galvano, 48 (Apt 510 C), Parque São Vicente -
                        Mauá
                      </b>{" "}
                      ou trazê-lo no dia da cerimônia.
                    </p>
                    <p>
                      Presente escolhido: <b>{selectedProduto.title}</b>
                    </p>
                    <div>
                      <label>Informe seu nome:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Digite aqui..."
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                      {nomeErro && (
                        <p style={{ fontSize: "14px" }} className="text-danger">
                          {nomeErro}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={closeModal}
                        disabled={loading}
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        className="btn btn-confirm"
                        onClick={confirmToggleEnabled}
                        disabled={loading}
                      >
                        {loading ? "Confirmando..." : "Confirmar"}
                      </button>
                    </div>
                    <p style={{ fontSize: "12px" }}>
                      *Caso queira trocar o presente após a escolha, entre em
                      contato com os noivos.
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      *Imagem meramente ilustrativa. Sinta-se à vontade para
                      fazer sua escolha.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CardItem;
