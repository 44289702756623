import React, { useEffect } from "react";
import "../../estilos/estilos.css";
import "../../estilos/home.css";
import layer from "../../img/Layer.png";
import CardItem from "../CardItem/CardItem";

const ProductsPage = () => {

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "40px 0",
          minHeight: "100vh",
          marginBottom: "10%"
        }}
      >
        <h1 className="title-main-section" style={{ marginTop: "40px" }}>
          Lista de Presentes
        </h1>
        <img
          className="img-main-section"
          src={layer}
          style={{ marginBottom: "20px" }}
        />
        <div>
          <CardItem />
        </div>
      </div>
    </>
  );
};

export default ProductsPage;
