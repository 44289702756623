import React from "react";
import "../estilos/estilos.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div style={{color: 'white', background: '#597455', padding: '15px 0'}} className="col-12 d-flex  align-items-center justify-content-center">
             {/* <a
              href="https://www.linkedin.com/in/tayane-souza/"
              target="_blank"
              style={{color: 'white'}}
              
            >  Desenvolvido por: Tayane Souza
            </a> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
